.zoomButtonsContainer {
  position: absolute;
  display: inline-block;
  right: 10px;
  bottom: 10px;
  float: right;
  z-index: 8;
  font: bold 30px 'Lucida Console', Monaco, monospace;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 0 5px #aaa;
  border-radius: 4px;
}

.compassButtonContainer {
  position: absolute;
  display: inline-block;
  right: 10px;
  top: 10px;
  float: right;
  z-index: 8;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 0 5px #aaa;
  border-radius: 15px;
}

button.zoomButtonPlus,
button.zoomButtonMinus {
  width: 56px;
  height: 56px;
  line-height: 56px;
  display: block;
  border-width: 0;
  background-color: #fff;
  color: #000;
  opacity: 0.9;
}

button.compassButton {
  width: 30px;
  height: 30px;
  padding-left: 0;
  display: block;
  border-width: 0;
  border-radius: 15px;
  background-color: #fff;
  color: #000;
  opacity: 0.9;
}

button.dark {
  background-color: #777;
  color: #eee;
  border-bottom-color: #999;
}

button.zoomButtonPlus {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #ccc;
}

button.zoomButtonMinus {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

button.zoomButtonPlus:hover,
button.zoomButtonMinus:hover,
button.compassButton:hover {
  background-color: #f6f6f6;
}

button.dark:hover {
  background-color: #888;
}
