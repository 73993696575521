html {
  /* This affects elements using rem measurements. 1rem equals html's font-size */
  font-size: 16px;
}

body {
  cursor: default;
  -webkit-user-select: none;
}

a,
a:hover,
a:visited,
a:active,
a:focus {
  text-decoration: none;
}

.main {
  margin-top: 15px;
  text-align: center;
}

html {
  font-family: 'Roboto', sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

#react-root {
  width: 100%;
  height: 100%;
  margin: 0;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
}

#map {
  width: 100%;
  height: 100%;
}

/* Bootstrap overrides */
button.list-group-item .list-group-item-heading {
  color: white;
}

.edit-button {
  border: 1px solid rgba(0, 0, 0, 0);
}

.edit-button:hover {
  border: 1px solid rgb(0, 0, 0);
  box-shadow: 0px 0px 0px 1px rgba(238, 238, 238, 0);
  background-color: #bfc6ce;
}

.list-group-item {
  border: 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.list-group-item:first-child {
  border-radius: 0;
}
.list-group-item:last-child {
  border-radius: 0;
}

button.btn {
  font-size: 0.85rem;
}

/* button.btn-dark, button.btn-dark:focus {
  background-color: #333;
  border-color: #aaa;
  color: #03A9F4;
} */

/* button.btn-dark:hover, button.btn-dark:active {
  background-color: #444;
  border-color: #aaa;
  color: #03A9F4;
} */

button.btn-dark-grey-text,
button.btn-dark-grey-text:focus {
  background-color: #333;
  border-color: #aaa;
  color: #ddd;
}

/* button.btn-dark-grey-text:hover, button.btn-dark-grey-text:active {
  background-color: #444;
  border-color: #aaa;
  color: #ddd;
} */

/* ul.dropdown-menu {
  background-color: #444;
} */

ul.dropdown-menu li a {
  color: #000000;
  font-size: 1.25rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

ul.dropdown-menu li a:focus,
ul.dropdown-menu li a:hover {
  background-color: #bfc6ce;
  color: #000000;
}

/* .Select-control, .Select-option {
  background-color: #444 !important;
  border-color: #aaa !important;
} */

/* .is-selected {
  background-color: #606060 !important;
}

.is-focused {
  background-color: #666 !important;
} */

.Select-value-label,
.Select-option {
  color: #eee !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  display: block;
  height: 0;
  background-color: transparent;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 0;
  -webkit-border-bottom-right-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
}
::-webkit-scrollbar-thumb:vertical {
  height: 50px;
  background-color: #999;
  -webkit-border-radius: 8px;
}
::-webkit-scrollbar-thumb:horizontal {
  width: 50px;
  background-color: #999;
  -webkit-border-radius: 8px;
}
