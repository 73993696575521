#infrastructureDropdown.dropdown-toggle.btn.btn-default {
  background-color: #ecf0f1;
  color: #000000;
  height: 3.4rem;
  border-width: 0 0 0 0;
}

#infrastructureDropdown.dropdown-toggle.btn.btn-default:hover {
  background-color: #bfc6ce;
  color: #000000;
  height: 3.4rem;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle):hover {
  background-color: #bfc6ce !important;
}

.dropdown-menu {
  max-height: 90vh;
  overflow-y: scroll;
}

a#customButton {
  padding-top: 8px;
  padding-bottom: 8px;
}

/* ul.dropdown-menu li a:hover {
  background-color: rgba(191, 198, 206, 0.5);
} */
